.c-transition {
    padding: 70px 0 75px;
    @include mq($from: desktop) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        min-width: 100%;
        height: 100px;
        min-height: calc(100vh - 103px);
        padding: 300px 0;
    }

    .o-wrapper {
        position: relative;
        width: 100%;
        margin: 0;
    }

    &__img {
        width: 148px;
        display: block;
        height: auto;
        margin: 0 auto;
        @include mq($from: tablet) {
            width: 294px;
        }
        @include mq($from: desktop) {
            width: 442px;
        }
        @include mq($from: wide) {
            width: 475px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }
    }

    &__title {
        font-family: $font-opensans;
        font-weight: 700;
        line-height: 1.25;
        text-align: center;
        color: #414648;
        margin: 14px 0 0;
        $ifz: 20px;
        $ffz: 24px;
        font-size: $ifz;
        @include mq($from: tablet) {
            font-size: font-increment($ifz, $ffz, 1);
            margin: 24px 0;
        }
        @include mq($from: desktop) {
            font-size: font-increment($ifz, $ffz, 2);
        }
        @include mq($from: wide) {
            font-size: $ffz;
        }
    }
    &__brand {
        width: 95px;
        height: 40px;
        display: block;
        margin: 0 auto;
        @include mq($from: desktop) {
            width: 180px;
            height: 80px;
        }
    }

    &__logo {
        width: 213px;
        display: block;
        height: auto;
        margin: 26px auto 0;
        @include mq($from: tablet) {
            width: 257px;
        }
        @include mq($from: desktop) {
            width: 347px;
        }
    }

    &__content {
        max-width: 428px;
    }
    &-list {
        margin: 46px 0 0;
        @include mq($from: desktop) {
            margin-top: 80px;
        }

        &__title {
            font-family: $font-oswald;
            font-weight: 700;
            line-height: 1.25;
            text-align: center;
            color: $color-gray;
            margin: 0;
            $ifz: 18px;
            $ffz: 32px;
            font-size: $ifz;
            @include mq($from: tablet) {
                font-size: font-increment($ifz, $ffz, 1);
            }
            @include mq($from: desktop) {
                font-size: font-increment($ifz, $ffz, 2);
            }
            @include mq($from: wide) {
                font-size: $ffz;
                text-align: left;
            }
        }

        &__list {
            margin-top: 17px;
            max-width: 445px;
            margin-left: auto;
            margin-right: auto;
            @include mq($from: desktop) {
                margin-top: 22px;
            }
            @include mq($from: wide) {
                margin-left: 0;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
            }
        }

        &__icon {
            display: flex;
            width: 46px;
            height: 46px;
            align-items: center;
            justify-content: center;
        }

        &__icon-img {
            width: 36px;
            height: auto;
            display: block;
        }

        &__text {
            width: calc(100% - 46px);
            padding-left: 14px;
            font-size: 15px;
            @include mq($from: tablet) {
                font-size: 16px;
            }
            @include mq($from: desktop) {
                font-size: 17px;
            }
            @include mq($from: wide) {
                font-size: 18px;
            }
        }
    }

    &-footer {
        background-color: $color-blue2;
        padding: 25px 0 30px;
        text-align: center;

        &__text {
            color: #fff;
            line-height: 1.37;
            font-size: 15px;
            @include mq($from: tablet) {
                font-size: 16px;
            }
            @include mq($from: desktop) {
                font-size: 17px;
            }
            @include mq($from: wide) {
                font-size: 18px;
            }

            p {
                margin: 0;
            }
            
            a {
                font-weight: 700;
                text-decoration: underline;
                color: #fff;
            }

            strong {
                font-weight: 700;
            }
        }
    }
}